body.custom-background {
  background-color: #23023b;
}

.header-image a {
  text-align: center;
}
h1.entry-title,
h2.entry-title {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

ol li {
  padding-bottom: 10px;
}

.entry-footer {
  font-family: "Roboto";
  font-weight: 300;
  margin-top: 28px;
}

.site-content .entry-content {
  font-family: "Roboto";
  font-weight: 300;
}

body,
button,
input,
select,
textarea,
h2.reply-title,
.comment-form .subscribe-label {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

.pagination .page-numbers {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

.widget,
.pagination {
  border-top: 1px solid #23023b;
}

.post-navigation {
  border-top: 1px solid #23023b;
  border-bottom: 1px solid #23023b;
}

.post-navigation div + div {
  border-top: 1px solid #23023b;
}

.widget .widget-title,
.post-navigation .post-title,
.meta-nav,
.page-header .page-title {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

.widget ul {
  list-style: none;
  margin-left: 0;
  font-size: 0.9rem;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="week"],
input[type="month"],
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
textarea {
  border: 1px solid #23023b;
  background-color: transparent;
}

.comments-title,
.comment-reply-title,
.page-header {
  border-top: 1px solid #23023b;
}

.widget button.search-submit {
  background-color: transparent;
  border: 1px solid #23023b;
  color: #23023b;
}

.pagination:before,
.pagination:after {
  background-color: transparent;
  border: 1px solid #23023b;
}

.comments-title,
.comment-reply-title,
.comment-form label {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

.pagination .nav-links:before,
.pagination .nav-links:after {
  color: #23023b;
}

.pagination .prev,
.pagination .next {
  background-color: transparent;
  color: #23023b;
}

button,
button[disabled]:hover,
button[disabled]:focus,
input[type="button"],
input[type="button"][disabled]:hover,
input[type="button"][disabled]:focus,
input[type="reset"],
input[type="reset"][disabled]:hover,
input[type="reset"][disabled]:focus,
input[type="submit"],
input[type="submit"][disabled]:hover,
input[type="submit"][disabled]:focus {
  background: transparent;
  border: 1px solid black;
  color: black;
}

mark,
ins,
button:hover,
button:focus,
input[type="button"]:hover,
input[type="button"]:focus,
input[type="reset"]:hover,
input[type="reset"]:focus,
input[type="submit"]:hover,
input[type="submit"]:focus,
.pagination .prev:hover,
.pagination .prev:focus,
.pagination .next:hover,
.pagination .next:focus,
.widget_calendar tbody a,
.page-links a:hover,
.page-links a:focus {
  color: rgba(236, 236, 236, 1);
}

.site-branding {
  margin: 0 auto;
  text-align: center;
}

.site-branding .site-title,
.site-branding .site-description {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  text-align: center;
}

ins.adsbygoogle {
  background-color: rgb(236, 236, 236);
}

ins.adsbygoogle::before {
  content: "Publicidad";
  position: absolute;
  top: -13px;
  color: black;
  font-family: "Raleway", sans-serif;
  font-size: 0.6rem;
}

@media screen and (min-width: 61.5625em) {
  body:not(.search-results) article:not(.type-page) .entry-content,
  body:not(.search-results) article:not(.type-page) .entry-footer {
    float: none;
    width: 100%;
    margin-top: 28px;
  }

  .site-title {
    font-size: 2.2rem;
  }

  font-size: 1.75rem;
  .entry-footer > span:not(:last-child):after {
    content: "\002f";
    display: inline-block !important;
    opacity: 0.7;
    padding: 0 0.538461538em;
  }

  .single .byline,
  .full-size-link,
  body:not(.search-results).group-blog .byline,
  body:not(.search-results) .entry-format,
  body:not(.search-results) .cat-links,
  body:not(.search-results) .tags-links,
  body:not(.search-results) article:not(.sticky) .posted-on,
  body:not(.search-results) article:not(.type-page) .comments-link,
  body:not(.search-results) article:not(.type-page) .entry-footer .edit-link {
    display: inline;
    padding-right: 3px;
  }

  .wp-caption img[class*="wp-image-"] {
    /*width: 100%;*/
  }
}

.cat-item a {
  display: block;
  padding: 0.2rem 0px;
  font-size: 1.0rem;
}

p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
}

h1 {
  font-family: "Karla";
  font-size: 51px;
  font-style: normal;
  font-weight: 400;
}

h2 {
  font-family: "Pontano Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

h3 {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
}

h6 {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
}

.site-main-enter {
  opacity: 0;
}
.site-main-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.site-main-exit {
  opacity: 1;
}
.site-main-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.splash-screen {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  background-color: #23023b;
}

@keyframes fade {
  from {
    opacity: 0.5;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

.splash-screen-logo {
  animation: fade 2000ms infinite;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./images/logo.png");
  background-size: 200px;
}

.opacity-enter {
  opacity: 0.01;
}

.opacity-enter.opacity-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.opacity-leave {
  opacity: 1;
}

.opacity-leave.opacity-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.site {
  margin: 0;
}

@import "css/twentysixteen.css";
@import "css/adsns.css";
@import "css/genericons.css";
@import "css/twentysixteen/style.css";
@import "css/twentysixteen/custom.css";
@import "css/jetpack.css";
@import "css/edit.scss";
@import "~react-smartbanner/dist/main.css";
