/* Custom Link Color */
.menu-toggle:hover,
.menu-toggle:focus,
a,
.main-navigation a:hover,
.main-navigation a:focus,
.dropdown-toggle:hover,
.dropdown-toggle:focus,
.social-navigation a:hover:before,
.social-navigation a:focus:before,
.post-navigation a:hover .post-title,
.post-navigation a:focus .post-title,
.tagcloud a:hover,
.tagcloud a:focus,
.site-branding .site-title a:hover,
.site-branding .site-title a:focus,
.entry-title a:hover,
.entry-title a:focus,
.entry-footer a:hover,
.entry-footer a:focus,
.comment-metadata a:hover,
.comment-metadata a:focus,
.pingback .comment-edit-link:hover,
.pingback .comment-edit-link:focus,
.comment-reply-link,
.comment-reply-link:hover,
.comment-reply-link:focus,
.required,
.site-info a:hover,
.site-info a:focus {
  color: #23023b;
}

mark,
ins,
button:hover,
button:focus,
input[type="button"]:hover,
input[type="button"]:focus,
input[type="reset"]:hover,
input[type="reset"]:focus,
input[type="submit"]:hover,
input[type="submit"]:focus,
.pagination .prev:hover,
.pagination .prev:focus,
.pagination .next:hover,
.pagination .next:focus,
.widget_calendar tbody a,
.page-links a:hover,
.page-links a:focus {
  background-color: #23023b;
}

input[type="date"]:focus,
input[type="time"]:focus,
input[type="datetime-local"]:focus,
input[type="week"]:focus,
input[type="month"]:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
.tagcloud a:hover,
.tagcloud a:focus,
.menu-toggle:hover,
.menu-toggle:focus {
  border-color: #23023b;
}

@media screen and (min-width: 56.875em) {

  .main-navigation li:hover>a,
  .main-navigation li.focus>a {
	color: #23023b;
  }
}