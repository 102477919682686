.edit-button {
    position: relative;
}

.edit-button .edit-overlay {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border: 1px solid transparent;
}

.edit-button .edit-overlay:hover {
    border: 1px solid black;
    cursor: pointer;
} 

.edit-button .edit-buttons-container {
    display: flex;
}