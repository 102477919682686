#content div.sharedaddy,
#main div.sharedaddy,
div.sharedaddy {
  clear: both;
}
div.sharedaddy h3.sd-title {
  margin: 0 0 1em 0;
  display: inline-block;
  line-height: 1.2;
  font-size: 9pt;
  font-weight: 700;
}
div.sharedaddy h3.sd-title:before {
  content: "";
  display: block;
  width: 100%;
  min-width: 30px;
  border-top: 1px solid #ddd;
  margin-bottom: 1em;
}
@media only screen and (max-width: 640px) {
  .jp-related-posts-i2__row {
    margin-top: 0;
    display: block;
  }
  .jp-related-posts-i2__row[data-post-count] .jp-related-posts-i2__post {
    max-width: none;
    margin: 0;
    margin-top: 1rem;
  }
  .jp-related-posts-i2__post-img-link {
    margin-top: 1rem;
  }
  .jp-related-posts-i2__post-img-link img {
    width: 350px;
  }
}
#jp-relatedposts {
  display: none;
  padding-top: 1em;
  margin: 1em 0;
  position: relative;
  clear: both;
}
.jp-relatedposts:after {
  content: "";
  display: block;
  clear: both;
}
#jp-relatedposts h3.jp-relatedposts-headline {
  margin: 0 0 1em 0;
  display: inline-block;
  float: left;
  font-size: 9pt;
  font-weight: 700;
  font-family: inherit;
}
#jp-relatedposts h3.jp-relatedposts-headline em:before {
  content: "";
  display: block;
  width: 100%;
  min-width: 30px;
  border-top: 1px solid #ddd;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1em;
}
#jp-relatedposts h3.jp-relatedposts-headline em {
  font-style: normal;
  font-weight: 700;
}
#jp-relatedposts .jp-relatedposts-items {
  clear: left;
}
#jp-relatedposts .jp-relatedposts-items .jp-relatedposts-post {
  float: left;
  width: 33%;
  margin: 0 0 1em;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
#jp-relatedposts .jp-relatedposts-items p,
#jp-relatedposts .jp-relatedposts-items-visual h4.jp-relatedposts-post-title {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
#jp-relatedposts .jp-relatedposts-items p {
  margin-bottom: 0;
}
#jp-relatedposts
  .jp-relatedposts-items
  .jp-relatedposts-post
  .jp-relatedposts-post-title
  a {
  font-size: inherit;
  font-weight: 400;
  text-decoration: none;
  -moz-opacity: 1;
  opacity: 1;
}
#jp-relatedposts
  .jp-relatedposts-items-visual
  .jp-relatedposts-post
  img.jp-relatedposts-post-img,
#jp-relatedposts .jp-relatedposts-items-visual .jp-relatedposts-post span {
  max-width: 100%;
}
#jp-relatedposts
  .jp-relatedposts-items
  .jp-relatedposts-post
  .jp-relatedposts-post-context,
#jp-relatedposts
  .jp-relatedposts-items
  .jp-relatedposts-post
  .jp-relatedposts-post-date {
  opacity: 0.6;
}
.jp-relatedposts-items .jp-relatedposts-post .jp-relatedposts-post-date {
  display: none;
}
@media only screen and (max-width: 640px) {
  #jp-relatedposts .jp-relatedposts-items .jp-relatedposts-post {
    width: 50%;
  }
  #jp-relatedposts .jp-relatedposts-items .jp-relatedposts-post:nth-child(3n) {
    clear: left;
  }
}
@media only screen and (max-width: 320px) {
  #jp-relatedposts .jp-relatedposts-items .jp-relatedposts-post {
    width: 100%;
    clear: both;
    margin: 0 0 1em;
  }
}
#content div.sharedaddy,
#main div.sharedaddy,
div.sharedaddy {
  clear: both;
}
div.sharedaddy h3.sd-title {
  margin: 0 0 1em 0;
  display: inline-block;
  line-height: 1.2;
  font-size: 9pt;
  font-weight: 700;
}
div.sharedaddy h3.sd-title:before {
  content: "";
  display: block;
  width: 100%;
  min-width: 30px;
  border-top: 1px solid #ddd;
  margin-bottom: 1em;
}
.sd-content ul {
  padding: 0 !important;
  margin: 0 0 0.7em 0 !important;
  list-style: none !important;
}
.sd-content ul li {
  display: inline-block;
}
.sd-content ul li {
  margin: 0 5px 5px 0;
  padding: 0;
}
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
